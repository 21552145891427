import * as React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import LaunchIcon from '@material-ui/icons/Launch'
import { Container, makeStyles, Theme, createStyles, Typography, Grid } from '@material-ui/core'
import IndexLayout from './IndexLayout'
import ProfileCard from '../components/ProfileCard'

interface IndexTemplateProps {
  site: {
    siteMetadata: {
      title: string
      description: string
      author: {
        name: string
        url: string
      }
    }
  }
  markdownRemark: {
    body: string
    html: string
    excerpt: string
    frontmatter: {
      title: string
      description: string
      featured_image: string
    }
  }
  allMarkdownRemark: {
    edges: AllMdxNode[]
  }
}

interface AllMdxNode {
  node: {
    excerpt: string
    fields: {
      slug: string
    }
    frontmatter: {
      date: string
      title: string
      description: string
      featured_image: {
        childImageSharp: any
      }
    }
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mdxSection: {
      textAlign: 'center'
    },
    blogListingSection: {
      marginTop: theme.spacing(1)
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    readMoreButton: {
      textDecoration: 'none'
    },
    media: {
      height: 0,
      paddingTop: '56.25%' // 16:9
    },
    header: {
      marginTop: '3rem',
      marginBottom: '0.5rem',
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        fontSize: '2rem'
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: '3rem'
      },
      '@media (max-width: 375px)': {
        fontSize: '1rem',
        marginTop: '1.2rem'
      },
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center'
      }
    }
  })
)

const IndexTemplate: React.FunctionComponent = () => {
  const classes = useStyles()
  return (
    <StaticQuery
      query={graphql`
        query BlogListingTemplateQuery {
          allMarkdownRemark(
            filter: { frontmatter: { templateKey: { eq: "blogpost" } } }
            sort: { fields: [frontmatter___date], order: DESC }
          ) {
            edges {
              node {
                excerpt
                fields {
                  slug
                }
                frontmatter {
                  date(formatString: "MMMM DD, YYYY")
                  title
                  description
                  featured_image {
                    childImageSharp {
                      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data: IndexTemplateProps) => (
        <IndexLayout title="News" description="Read the latest Electric Vehicle Financing and FinTech news from Zeti">
          <Container maxWidth="md">
            <Grid container justify="center">
              <Grid item xs={12}>
                <Typography className={classes.header} variant="h6">
                  News:
                </Typography>
              </Grid>
              <div style={{ height: '5vh' }} />
              <Grid item xs={11} sm={9}>
                <Grid className={classes.blogListingSection} container direction="row" justify="center" alignItems="stretch" spacing={3}>
                  {data.allMarkdownRemark.edges.map((edge: any) => {
                    return (
                      <ProfileCard
                        header={edge.node.frontmatter?.title}
                        subheader={edge.node.frontmatter?.date}
                        image={edge.node.frontmatter?.featured_image?.childImageSharp?.gatsbyImageData}
                        mainText={edge.node.excerpt}
                        ctaLink={edge.node.fields.slug}
                        ctaIcon={LaunchIcon}
                      />
                    )
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </IndexLayout>
      )}
    />
  )
}

export default IndexTemplate
